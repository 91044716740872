import React from "react"
import blogPostStyles from "./blogPost.module.scss"
import { Link } from "gatsby"

export default function Blog(props) {
  return (
    <div className={blogPostStyles.container}>
      <div className={blogPostStyles.imgContainer}>
        <img className={blogPostStyles.img} src={props.img} alt={props.alt} />
      </div>
      <p className={blogPostStyles.title}>{props.title}</p>
      <Link
        style={{ textDecoration: "none", width: "80%", maxWidth: '240px' }}
        to={`/publicaciones/${props.url}`}
      >
        <button className={blogPostStyles.button}>Conocer más</button>
      </Link>
    </div>
  )
}
