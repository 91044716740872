import React from "react"
import blogStyles from "./blogWithoutHeader.module.scss"
import BlogPost from "../home/blogPost"
import { useStaticQuery, graphql } from "gatsby"

export default function Blog(props) {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            postImage {
              id
              file {
                url
                fileName
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={blogStyles.container}>
      <div className={blogStyles.postsContainer}>
        <div className={blogStyles.gridContainer}>
          {data.allContentfulBlogPost.edges.map(edge => {
            return (
              <BlogPost
                title={edge.node.title}
                url={edge.node.slug}
                img={edge.node.postImage.file.url}
                alt={edge.node.postImage.file.fileName}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
