import React from "react"
import Layout from '../components/layout/layout'
import HeroWithTitle from "../components/publicaciones/heroWithTitle"
import Blog from "../components/publicaciones/blogWithoutHeader"

export default () => (
  <Layout>
    <HeroWithTitle />
    <Blog />
  </Layout>
)
