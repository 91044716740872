import React from "react"
import heroWithTitleStyles from "./heroWithTitle.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function HeroWithImage() {
  let data = useStaticQuery(graphql`
    query IndexPublicacionesHero {
      allAirtable(
        filter: { data: { section: { eq: "Publicaciones" }, data: {} } }
      ) {
        nodes {
          table
          data {
            Name
            data
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let img = data.allAirtable.nodes.filter(node => node.data.Name === "img")

  let heroWithTitle = {
    img: img[0].data.img[0].thumbnails.full.url,
    title: "Publicaciones",
  }
  return (
    <div>
      <div className={heroWithTitleStyles.container}>
        <div className={heroWithTitleStyles.imgContainer}>
          <img
            className={heroWithTitleStyles.img}
            src={heroWithTitle.img}
            alt="heroImg"
          />
        </div>
        <div className={heroWithTitleStyles.titleContainer}>
          <h1 className={heroWithTitleStyles.title}>{heroWithTitle.title}</h1>
        </div>
      </div>
    </div>
  )
}
